import { Box, Typography } from '@mui/material';
import bottle from '../../assets/bottle_basic.png';
import BPOM from '../../assets/bpom_logo.png';
import { maxFontSize, responsiveFontSize } from '../../helpers/general';

const benefitOfLunoar = [
  'Tanpa bahan Retinol',
  'Mencegah Penuaan Dini',
  'Mengurangi Jerawat',
  'Mencerahkan warna kulit',
  'Melembabkan kulit',
];

const Bottle = ({ matches }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        my: 3,
        px: 3,
        gap: matches ? responsiveFontSize(32) + 'vw' : maxFontSize(32) + 'px',
      }}
    >
      <Box sx={{ display: 'flex', flex: 0 }}>
        <Box
          data-aos='zoom-out'
          data-aos-duration={750}
          component='img'
          src={BPOM}
          sx={{
            width: matches
              ? responsiveFontSize(44) + 'vw'
              : maxFontSize(44) + 'px',
            height: matches
              ? responsiveFontSize(31) + 'vw'
              : maxFontSize(31) + 'px',
          }}
        />
        <Box
          data-aos='zoom-out'
          data-aos-duration={750}
          component='img'
          src={bottle}
          sx={{
            width: matches
              ? responsiveFontSize(88) + 'vw'
              : maxFontSize(88) + 'px',
            height: matches
              ? responsiveFontSize(233) + 'vw'
              : maxFontSize(233) + 'px',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          // justifyContent: 'center',
          mt: matches ? responsiveFontSize(24) + 'vw' : maxFontSize(24) + 'px',
        }}
      >
        {benefitOfLunoar.map((list) => (
          <Box
            color='primary.main'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#FFE179',
              px: 1,
              py: 0.5,
              fontSize: matches
                ? responsiveFontSize(10) + 'vw'
                : maxFontSize(10) + 'px',
              borderRadius: '10px',
              fontWeight: 500,
              width: matches
                ? responsiveFontSize(152) + 'vw'
                : maxFontSize(152) + 'px',
              height: matches
                ? responsiveFontSize(21) + 'vw'
                : maxFontSize(21) + 'px',
            }}
          >
            {list}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default Bottle;
