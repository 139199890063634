import { Box, Typography } from '@mui/material';

const Section5 = () => {
  const vitamins = [
    { name: 'B3', desc: 'Niacinimide' },
    { name: 'B5', desc: 'Mengembalikan Fungsi Skin Barrier' },
    { name: 'C', desc: 'Ekstrak Mulbery & Strawberry' },
    { name: 'Hyluronic Acid', desc: 'Mempercepat Penyembuhan Luka' },
    { name: 'Phenoxy-ethanol', desc: 'Mengurangi Jerawat' },
    { name: 'Zat Bakuchiol', desc: 'Mencegah Penuaan Dini' },
  ];

  return (
    <Box
      sx={{
        mt: 4,
        py: 4,
        px: 2,
        bgcolor: 'primary.light',
        color: 'primary.contrastText',
      }}
    >
      <Typography
        data-aos='zoom-in-up'
        data-aos-duration={500}
        align='center'
        sx={{ fontWeight: 700, fontFamily: `'Montserrat', sans-serif` }}
      >
        Serum Barrier Lunoar ini kaya akan
      </Typography>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          columnGap: 1,
          rowGap: 2,
        }}
      >
        {vitamins.map((vitamin, index) => (
          <Box
            data-aos='zoom-in-up'
            data-aos-duration={500}
            data-aos-delay={(index + 1) * 200}
            key={index}
            sx={{
              width: '.3',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                color: 'primary.main',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                align='center'
                sx={{
                  fontSize: index < 3 ? '1rem' : '0.75rem',
                  fontWeight: 600,
                  lineHeight: 1.25,
                  fontFamily: `'Poppins', sans-serif`,
                  color: '#000000',
                }}
              >
                {vitamin.name}
              </Typography>
              {index < 3 && (
                <Typography
                  align='center'
                  sx={{
                    fontSize: '0.625rem',
                    fontWeight: 600,
                    lineHeight: 1.25,
                    fontFamily: `'Poppins', sans-serif`,
                    color: '#000000',
                  }}
                >
                  Vitamin
                </Typography>
              )}
            </Box>

            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                align='center'
                sx={{
                  fontSize: '0.625rem',
                  mt: 1,
                  fontFamily: `'Source Sans Pro', sans-serif`,
                }}
              >
                {vitamin.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default Section5;
